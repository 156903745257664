<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <q-drawer show-if-above
    v-bind="$attrs" :value="props.value"
    side="left"
    bordered
    :mini="miniState"
    @mouseover="miniState = false"
    @mouseout="miniState = true"
    :breakpoint="500"
    v-if="$route.fullPath != '/login' && $route.fullPath != '/forgot-password'
      && $route.fullPath != '/new-password'"
    :width="336"
    class="column justify-between no-wrap"
    style="padding: 10px;">
    <q-list padding class="menu-list">
      <q-item>
        <div>
          <q-img class="logo" src="@/assets/logo.svg" height="auto" width="160px"></q-img>
          <q-img class="logo-mini"
            src="@/assets/mini-logo.svg" height="auto" width="20px"></q-img>
        </div>
      </q-item>

      <q-item clickable v-ripple :active="$route.name == 'home'"
        @click="$router.push({ name: 'home'})">
        <q-item-section avatar>
          <!-- <q-img height="auto" width="20px" src="@/assets/icons/homeIcon.svg"/> -->
          <HomeIcon :active="$route.name == 'home'"></HomeIcon>
        </q-item-section>

        <q-item-section>
          Inicio
        </q-item-section>
      </q-item>

      <PermissionChecker permission="production_orders_view">
        <q-expansion-item
          expand-separator
          active
          :content-inset-level="0.5">
          <template v-slot:header>
            <q-item-section avatar>
              <OrdersIcon />
            </q-item-section>

            <q-item-section>
              Ordenes de producción
            </q-item-section>
          </template>
          <q-item
            :active="$route.name == 'ProductionOrdersView'"
            clickable
            v-ripple
            @click="$router.push({ name: 'ProductionOrdersView'})">
            <q-item-section avatar>
              <OrdersIcon :active="$route.name == 'ProductionOrdersView'"></OrdersIcon>
              <!-- <q-img height="auto" width="20px" src="@/assets/icons/ordersIcon.svg"/> -->
            </q-item-section>
            <q-item-section>
              Activas
            </q-item-section>
          </q-item>
          <q-item
            :active="$route.name == 'ProductionOrdersHistoryView'"
            clickable
            v-ripple
            @click="$router.push({ name: 'ProductionOrdersHistoryView'})">
            <q-item-section avatar>
              <OrdersIcon :active="$route.name == 'ProductionOrdersHistoryView'"></OrdersIcon>
              <!-- <q-img height="auto" width="20px" src="@/assets/icons/ordersIcon.svg"/> -->
            </q-item-section>
            <q-item-section>
              Histórico
            </q-item-section>
          </q-item>
        </q-expansion-item>
      </PermissionChecker>

      <PermissionChecker permission="production_orders_view">
        <q-item
          :active="$route.name == 'ProductionRegisterView'"
          clickable
          v-ripple
          @click="$router.push({ name: 'ProductionRegisterView'})">
          <q-item-section avatar>
            <RegisterIcon :active="$route.name == 'ProductionRegisterView'"></RegisterIcon>
            <!-- <q-img height="auto" width="20px" src="@/assets/icons/registerIcon.svg"/> -->
          </q-item-section>

          <q-item-section>
            Registro de producción
          </q-item-section>
        </q-item>
      </PermissionChecker>

      <q-item
          :active="$route.name == 'NewProductionDashboard'"
          clickable
          v-ripple
          @click="$router.push({ name: 'NewProductionDashboard'})">
          <q-item-section avatar>
            <RegisterIcon :active="$route.name == 'NewProductionDashboard'"></RegisterIcon>
            <!-- <q-img height="auto" width="20px" src="@/assets/icons/registerIcon.svg"/> -->
          </q-item-section>

          <q-item-section>
            Calendario de producción
          </q-item-section>
        </q-item>

      <!-- <PermissionChecker permission="production_orders_view">
        <q-item
          :active="$route.name == 'InventoryView'"
          clickable
          v-ripple
          @click="$router.push({ name: 'InventoryView'})">
          <q-item-section avatar>
            <q-img height="auto" width="20px" src="@/assets/icons/inventoryIcon.svg"/>
            <InventoryIcon :active="$route.name == 'InventoryView'"></InventoryIcon>
          </q-item-section>

          <q-item-section>
            Inventarios
          </q-item-section>
        </q-item>
      </PermissionChecker> -->

      <q-item
        :active="$route.name == 'RawMaterialsDeliveriesView'"
        clickable
        v-ripple
        @click="$router.push({ name: 'RawMaterialsDeliveriesView'})">
        <q-item-section avatar>
          <!-- <q-img height="auto" width="20px" src="@/assets/icons/inventoryIcon.svg"/> -->
          <!-- <InventoryIcon  :active="$route.name == 'InventoryView'"></InventoryIcon> -->
          <InventoryIcon :active="$route.name == 'RawMaterialsDeliveriesView'"></InventoryIcon>
        </q-item-section>

        <q-item-section>
          Ordenes de compra
        </q-item-section>
      </q-item>

      <PermissionChecker permission="products_view">
        <q-expansion-item
          expand-separator
          icon="dynamic_feed"
          label="Otros"
          active
          :content-inset-level="0.5">
          <q-item
            :active="$route.name == 'ProductsView'"
            clickable
            v-ripple
            @click="$router.push({ name: 'ProductsView'})">
            <q-item-section avatar>
              <ProductsIcon :active="$route.name == 'ProductsView'"></ProductsIcon>
            </q-item-section>
            <q-item-section>
              Productos
            </q-item-section>
          </q-item>
          <q-item
            :active="$route.name == 'ColorsView'"
            clickable
            v-ripple
            @click="$router.push({ name: 'ColorsView'})">
            <q-item-section avatar>
              <ProductsIcon :active="$route.name == 'ColorsView'"></ProductsIcon>
            </q-item-section>
            <q-item-section>
              Colores
            </q-item-section>
          </q-item>
          <q-item :active="$route.name == 'MaterialsView'"
            clickable
            v-ripple
            @click="$router.push({ name: 'MaterialsView'})">
            <q-item-section avatar>
              <ProductsIcon :active="$route.name == 'MaterialsView'"></ProductsIcon>
            </q-item-section>
            <q-item-section>
              Materiales
            </q-item-section>
          </q-item>
          <q-item :active="$route.name == 'RawMaterialsView'"
            clickable
            v-ripple
            @click="$router.push({ name: 'RawMaterialsView'})">
            <q-item-section avatar>
              <ProductsIcon :active="$route.name == 'RawMaterialsView'"></ProductsIcon>
            </q-item-section>
            <q-item-section>
              Materia prima
            </q-item-section>
          </q-item>
        </q-expansion-item>
      </PermissionChecker>
    </q-list>
    <q-item class="col-grow">
    </q-item>
    <q-item
        clickable
        v-ripple
        @click="logoutUser">
        <q-item-section avatar>
          <q-icon name="logout" />
          <!-- <q-img height="auto" width="20px" src="@/assets/icons/registerIcon.svg"/> -->
        </q-item-section>
        <q-item-section>
          Cerrar Sesión
        </q-item-section>
    </q-item>
  </q-drawer>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import PermissionChecker from '@/components/PermissionChecker.vue';
import HomeIcon from './icons/HomeIcon.vue';
import InventoryIcon from './icons/InventoryIcon.vue';
import ProductsIcon from './icons/ProductsIcon.vue';
import OrdersIcon from './icons/OrdersIcon.vue';
import RegisterIcon from './icons/RegisterIcon.vue';
import { mapActions } from '../helpers/stateLib';

const { logout } = mapActions('auth');
const router = useRouter();

const logoutUser = async () => {
  await logout();
  router.push({ name: 'Login' });
};

const props = defineProps({
  value: String,
});

const miniState = ref(true);
</script>

<script>
export default {
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
.drawer-img{
  margin: 10px;
}
.drawer-mini-img{
  margin: 3px;
  padding: 5px;
}
.menu-list{
  font-family: 'Jost', sans-serif;
}
.q-item.q-router-link--active, .q-item--active {
  color: $primary !important;
  background: #E7E7FF;
  border-radius: 8px;
  font-family: 'Jost', sans-serif;
}
.q-item.q-router-link, .q-item {
  border-radius: 8px;
}
.logo{
  display: block;
}
.logo-mini{
  display: none;
}
::v-deep{
  .q-drawer--mini .logo{
    display: none;
  }
  .q-drawer--mini .logo-mini{
    display: block;
  }
}
</style>
